import * as React from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "@epic-mod-market/core";
import {
  Box,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { useIsPlatformDisabled } from "../../hooks";
import { platformsMap } from "../../../../types";
import { StyledCheckbox } from "./PlatformItem.styles";
import { OverflowTooltip } from "../../../../../../../components/OverflowTooltip";

interface PlatformItemProps {
  selected: boolean;
  platform: Platform;
  onChange: (platform: Platform) => void;
  nameOfBuildPlatformAssignedTo?: string;
  buildVersion: string;
}

export const PlatformItem: React.FC<PlatformItemProps> = ({
  selected,
  platform,
  onChange,
  nameOfBuildPlatformAssignedTo,
  buildVersion,
}) => {
  const { t } = useTranslation();
  const isDisabled = useIsPlatformDisabled(platform);

  const handleChange = React.useCallback(() => onChange(platform), [
    onChange,
    platform,
  ]);

  const platformNote = React.useMemo(() => {
    if (isDisabled) {
      return t("activate_binary.artifacts.set_platforms.not_available");
    }

    if (buildVersion === nameOfBuildPlatformAssignedTo) {
      return t("activate_binary.artifacts.set_platforms.assigned");
    }

    if (Boolean(nameOfBuildPlatformAssignedTo)) {
      return t("activate_binary.artifacts.set_platforms.assigned_to_build", {
        buildVersion: nameOfBuildPlatformAssignedTo,
      });
    }

    return t("activate_binary.artifacts.set_platforms.available");
  }, [buildVersion, nameOfBuildPlatformAssignedTo, isDisabled]);

  const tooltipText = isDisabled
    ? t("activate_binary.artifacts.set_platforms.not_available.description")
    : "";

  return (
    <Tooltip title={tooltipText} placement="bottom">
      <div>
        <ListItem
          dense
          button
          onClick={handleChange}
          selected={selected}
          disabled={isDisabled}
        >
          <Box display="flex">
            <ListItemText
              primary={<Typography>{platformsMap[platform]}</Typography>}
            />
            <StyledCheckbox
              checked={selected}
              color="primary"
              onChange={handleChange}
              disabled={isDisabled}
            />
          </Box>
          <Box display="flex" mt={-2}>
            <Typography noWrap variant="caption">
              <OverflowTooltip text={platformNote}>
                {platformNote}
              </OverflowTooltip>
            </Typography>
          </Box>
        </ListItem>
      </div>
    </Tooltip>
  );
};
