import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const ENABLED_ENVS: string[] = ["prod", "rc", "gamedev"];

export const configureSentry = (): void => {
  const env = process.env.REACT_APP_EPIC_ENV;
  const isErrorReportingEnabled = env ? ENABLED_ENVS.includes(env) : false;

  if (!isErrorReportingEnabled) {
    return;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_EPIC_SENTRY_DSN,
    environment: env,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
  });
};
