import styled from "styled-components";

export const OverflowTooltipContent = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  /* default tooltip is hidden in Safari */
  &::after {
    content: "";
    display: block;
  }
`;
