import * as React from "react";
import { Tooltip, TooltipProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { OverflowTooltipContent } from "./OverflowTooltip.styles";

export interface OverflowTooltipProps {
  children: React.ReactNode;
  text: TooltipProps["title"];
  placement?: TooltipProps["placement"];
  className?: string;
}

const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: 400,
  },
}));

/**
 * A component that is used to show a tooltip with the full text if the string is too long
 */
export const OverflowTooltip: React.FC<OverflowTooltipProps> = ({
  children,
  text,
  placement = "bottom",
  className,
}) => {
  const classes = useStyles();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [isTextTooWide, setIsTextTooWide] = React.useState(false);

  const showTooltipIfTextTooWide = () => {
    if (containerRef.current) {
      setIsTextTooWide(
        containerRef.current.scrollWidth > containerRef.current.clientWidth
      );
    }
  };

  return (
    <Tooltip
      title={text}
      placement={placement}
      arrow
      disableHoverListener={!isTextTooWide}
      disableTouchListener={!isTextTooWide}
      disableFocusListener={!isTextTooWide}
      classes={classes}
    >
      <OverflowTooltipContent
        className={className}
        ref={containerRef}
        onMouseEnter={showTooltipIfTextTooWide}
      >
        {children}
      </OverflowTooltipContent>
    </Tooltip>
  );
};

export default OverflowTooltip;
